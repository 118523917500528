<template>
<div v-if="loading">
      <b-spinner variant="primary"></b-spinner>
</div>
<v-select v-else ref="team-select-select" v-model="selected" :options="teams" label="dispname" placeholder="Välj lag" :multiple="false">
      <template #selected-option="team">
            <div style="display: flex; align-items: baseline">
                  <div>
                        <b-img :src="flag(team)" />
                  </div>
                  <div>
                        {{team.dispname}}
                  </div>
            </div>
      </template>
      <template #option="team">
            <b-img :src="flag(team)" />
            <span class="ml-2">{{team.dispname}}</span>
      </template>
</v-select>
</template>

<script>
export default {
      name: "TeamSelectNew",     
      props:{
            value: Object, // the current team
            idlist: Object // list of teams to use
      },    
      data() {
            return {
                  selected: false
            };
      },
      computed: {
            loading() {
                  return this.$store.getters["teams/teamsloading"];
            },
            teams() {
                  var all = this.$store.getters["teams/teams"];
                  var teams = [];
                  for (var id in all) {
                        if(this.useTeam(id))
                              teams.push(all[id]);
                  }
                  return teams.sort(function (a, b) {
                        if (a.dispname < b.dispname) //sort string ascending
                              return -1
                        if (a.dispname > b.dispname)
                              return 1
                        return 0 //default return value (no sorting)
                  });
            }           
      },
      methods: {
            useTeam(id){
                  if(this.$functions.empty(this.idlist)){
                        return true;
                  }
                  if(this.idlist[id])
                        return true;
            },
            flag(team) {
                  return this.$images.teamFlag(team.flag);
            }
      },
      watch: {
            selected(team) {
                  this.$emit("input", team);                  
            }
      }
};
</script>
